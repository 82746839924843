var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"list-z"},[_c('el-breadcrumb',{staticClass:"fs-18",attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{staticStyle:{"color":"#FFFFFF"},attrs:{"to":{ path: '/enterprise/home' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("电子证照")])],1)],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"project"},[_c('div',{staticClass:"project_from"},[_c('div',{staticClass:"flex_center project_see"},[_c('div',{staticClass:"flex_center project_see_info"},[_c('span',[_vm._v("建筑工程施工许可证电子证照：")]),_c('el-input',{staticClass:"project_see_info_input",attrs:{"placeholder":"请输入建筑工程施工许可证照","clearable":""},model:{value:(_vm.certnum),callback:function ($$v) {_vm.certnum=$$v},expression:"certnum"}}),_c('el-button',{staticClass:"project_see_info_btn",attrs:{"type":"primary"},on:{"click":_vm.getCertificateList}},[_vm._v("立即查询")]),_c('el-button',{on:{"click":function($event){return _vm.handleReset()}}},[_vm._v("重置")])],1)]),_c('div',{staticClass:"project_screen"},[_vm._m(0),_c('div',{staticClass:"flex project_screen_list"},_vm._l((_vm.columnList),function(item,index){return _c('div',{key:index,class:[
              item.type === 'disable' && 'project_screen_list_item_off',
              _vm.listItem === index && 'project_screen_list_item_on',
              'project_screen_list_item',
            ],on:{"click":function($event){return _vm.onScreen(item, index)}}},[_c('span',[_vm._v(_vm._s(item.name))])])}),0)])]),_vm._m(1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading1),expression:"loading1"}]},[(_vm.certificateList.length !== 0)?_c('div',{staticClass:"project_list"},_vm._l((_vm.certificateList),function(item,index){return _c('div',{key:index,staticClass:"flex project_list_item",on:{"click":function($event){return _vm.onCertificateDetails(item)}}},[_c('div',{staticClass:"flex_row_center project_list_item_serial"},[_c('span',[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"flex_column project_list_item_text"},[_c('span',[_vm._v(_vm._s(item.projname))]),_c('span',[_vm._v(_vm._s(item.citynum)+" -"+_vm._s(item.countynum))]),_c('span',[_vm._v("施工许可证电子证照编号："+_vm._s(item.certnum || "-"))]),_c('span',[_vm._v("项目类别："+_vm._s(item.prjtypenum || "-"))]),_c('span',[_vm._v("建设单位："+_vm._s(item.projowner || "-"))])])])}),0):_c('div',{staticClass:"project_err"},[_c('span',[_vm._v("暂无数据")])]),_c('div',{staticClass:"flex project_page"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-size":_vm.pageSize,"layout":"prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex project_screen_describe"},[_c('span',[_vm._v("省份筛选：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex_center project_title"},[_c('div',{staticClass:"flex_row_center project_title_index"},[_c('span',[_vm._v("序号")])]),_c('span',[_vm._v("电子证照内容")])])
}]

export { render, staticRenderFns }