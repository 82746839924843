<template>
  <div>
    <div class="list-z">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
        <el-breadcrumb-item :to="{ path: '/enterprise/home' }" style="color: #FFFFFF;">首页</el-breadcrumb-item>
        <el-breadcrumb-item>电子证照</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="project" v-loading="loading">
      <div class="project_from">
        <div class="flex_center project_see">
          <div class="flex_center project_see_info">
            <span>建筑工程施工许可证电子证照：</span>
            <el-input
              class="project_see_info_input"
              placeholder="请输入建筑工程施工许可证照"
              v-model="certnum"
              clearable
            ></el-input>
            <el-button
              class="project_see_info_btn"
              type="primary"
              @click="getCertificateList"
              >立即查询</el-button
            >
            <el-button @click="handleReset()">重置</el-button>
          </div>
        </div>
        <div class="project_screen">
          <div class="flex project_screen_describe">
            <span>省份筛选：</span>
          </div>
          <div class="flex project_screen_list">
            <div
              :class="[
                item.type === 'disable' && 'project_screen_list_item_off',
                listItem === index && 'project_screen_list_item_on',
                'project_screen_list_item',
              ]"
              v-for="(item, index) in columnList"
              :key="index"
              @click="onScreen(item, index)"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex_center project_title">
        <div class="flex_row_center project_title_index">
          <span>序号</span>
        </div>
        <span>电子证照内容</span>
      </div>
      <div v-loading="loading1">
        <div class="project_list" v-if="certificateList.length !== 0">
          <div
            class="flex project_list_item"
            v-for="(item, index) in certificateList"
            :key="index"
            @click="onCertificateDetails(item)"
          >
            <div class="flex_row_center project_list_item_serial">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="flex_column project_list_item_text">
              <span>{{ item.projname }}</span>
              <span>{{ item.citynum }} -{{ item.countynum }}</span>
              <span>施工许可证电子证照编号：{{ item.certnum || "-" }}</span>
              <span>项目类别：{{ item.prjtypenum || "-" }}</span>
              <span>建设单位：{{ item.projowner || "-" }}</span>
            </div>
          </div>
        </div>
        <div class="project_err" v-else>
          <span>暂无数据</span>
        </div>
        <div class="flex project_page">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { urlCertificateList, urlColumnList } from '@/api/account'
  import cache from '@/libs/util.cache'
export default {
  data() {
    return {
      listItem: 0,
      certificateList: [],
      columnList: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
        certnum: '',
      current: 1,
        provincenum: '',
      loading: true,
        loading1: false
    };
  },
  mounted() {
      document.title = "电子证照_邦建通";
      this.getCertificateList()
      this.getColumnList()
  },
  components: {},
  methods: {
    handleReset() {
        this.provincenum = '';
        this.listItem = 0;
        this.certnum = '';
        this.current = 1;
      },
      onCertificateDetails(e) {
        cache.setSessionStorage('certificateDetails', e)
        this.$router.push({path: '/certificate/details'})
      },
      async getCertificateList() {
        this.loading1 = true
        const param = {
          certnum: this.certnum,
          current: this.current,
          projname: '',
          provincenum: this.provincenum,
          size: this.pageSize
        }
        const res = await urlCertificateList(param)
        const { pages, records, size, total } = res.data
        this.certificateList = records
        this.total = total
        this.loading1 = false
        this.currentPage = this.current
      },
      async getColumnList() {
        const param = `appid=wxbd94b37dd45c1f91`
        const res = await urlColumnList(param)
        const id = res.data.filter(item => item.name === "电子证件照省份分类")[0].id
        const province = res.data.filter(item => item.pid === id)
        this.columnList = [{name:'全国'}].concat(province)
        this.loading = false
      },
      onScreen(item, index) {
        if (this.listItem === index || item.type === 'disable') return
        this.provincenum = index == 0 ? '' : item.name;
        this.listItem = index;
        this.getCertificateList();
      },
      handleCurrentChange(val) {
        if (this.current === val) return
        this.current = val
        this.getCertificateList()
      }
  },
};
</script>
<style scoped lang="less">
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 35px;
  margin-bottom: 25px;
  .fs-18 {
    font-size: 18px;
  }
}
.project {
  .project_from {
    border: 1px solid #dcdcdc;
    .project_see {
      flex: 1;
      height: 110px;
      border-bottom: 1px solid #dcdcdc;
      .project_see_info {
        flex: 1;
        padding: 0 40px;
        .project_see_info_input {
          flex: 1;
          margin-left: 10px;
          margin-right: 60px;
        }
        .project_see_info_btn {
          width: 160px;
        }
      }
    }
    .project_screen {
      .project_screen_describe {
        padding: 30px 20px 0 20px;
      }
      .project_screen_list {
        flex-wrap: wrap;
        padding: 20px;
        margin: -5px;
        .project_screen_list_item {
          margin: 5px;
          cursor: pointer;
          font-size: 18px;
          > span {
            height: 40px;
            line-height: 40px;
            display: inline-block;
            padding: 0 12px;
          }
        }
        .project_screen_list_item_on {
          background-color: #edf4ff;
          border-radius: 4px;
          color: #4e4e4e;
          font-weight: bold;
        }
        .project_screen_list_item_off {
          color: #888888;
        }
      }
    }
  }
  .project_title {
    height: 60px;
    background-color: #f8f8f8;
    .project_title_index {
      width: 90px;
      min-width: 90px;
      padding-right: 20px;
    }
  }
  .project_list {
    .project_list_item {
      border: 1px solid #dcdcdc;
      border-top: aliceblue;
      padding: 30px 0;
      cursor: pointer;
      .project_list_item_serial {
        align-items: baseline;
        width: 90px;
        min-width: 90px;
        padding-right: 20px;
        font-size: 18px;
      }
      .project_list_item_text {
        font-size: 20px;
        text-align: left;
        line-height: 1;
        > span:nth-child(1) {
          line-height: normal;
          color: #4e4e4e;
          font-weight: bold;
          padding-bottom: 12px;
        }
        > span:nth-child(2) {
          color: #212121;
          font-weight: 500;
          padding-bottom: 20px;
        }
        > span:nth-child(3) {
          color: #4e4e4e;
          padding-bottom: 12px;
        }
        > span:nth-child(4) {
          color: #4e4e4e;
          padding-bottom: 12px;
        }
        > span:nth-child(5) {
          color: #4e4e4e;
          padding-bottom: 12px;
        }
      }
    }
  }
  .project_err {
    padding: 30px 0;
    font-size: #888888;
  }
  .project_page {
    justify-content: flex-end;
    padding: 40px 20px;
  }
}
.project
  .project_from
  .project_screen
  .project_screen_list
  .project_screen_list_item_on {
  color: #007ef2;
}
.project .project_list .project_list_item:hover {
  background-color: #f5f7fa;
}
::v-deep .project .project_list .project_list_item .project_list_item_text {
  font-size: 16px;
}
::v-deep .el-button--primary {
  background-color: #007ef2;
  border-color: #007ef2;
}
.project
  .project_list
  .project_list_item
  .project_list_item_text
  > span:nth-child(1):hover {
  color: #007ef2;
}
</style>
